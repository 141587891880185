import React, { useRef, useEffect } from 'react'
import { Box, Typography, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Mark from 'mark.js'
import kebabCase from 'lodash/kebabCase'
import { Trans } from 'gatsby-plugin-react-i18next'
import FAQGroup from './FAQGroup'
import { FAQPage as ContentfulFAQPage } from '../../types/faq'
import useFAQSearch from './useFAQSearch'
import SearchInput from '../Search/SearchInput'
import JumpLink from '../Link/JumpLink'
import NotFound from '../Errors/NotFound'
import XRayWrapper from '../XRay/XRayWrapper'
import useBrand from '../../hooks/useBrand'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BindToFields } from '../../types/contentfulAttributes'
import { useTranslation } from 'react-i18next'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'

interface Props {
  faqPage: ContentfulFAQPage
}

const FAQPage: React.FC<Props> = ({ faqPage }) => {
  const { classes, cx } = useStyles()
  const faqContainer = useRef<HTMLDivElement>()
  const { searchTerm, setSearchTerm, faqGroups } = useFAQSearch(
    faqPage.faqGroups
  )
  const { isZipJob, name: brandName } = useBrand()
  const applyContentfulStyles = createContentfulClassGetter(faqPage.css)
  const { t } = useTranslation()
  const handlebarsContext = useHandlebarsContext()

  // On each searchTerm change, highlight the text that matches the search.
  useEffect(() => {
    if (!searchTerm || !faqContainer.current) {
      return
    }

    const markInstance = new Mark(faqContainer.current)
    markInstance.mark(searchTerm, {
      exclude: ['h5'],
    })

    return function cleanup() {
      markInstance.unmark()
    }
  }, [searchTerm])

  return (
    <XRayWrapper id={faqPage.contentful_id}>
      <Box
        className={cx(classes.root, applyContentfulStyles(BindToFields.Main))}
        component="main"
      >
        <Box
          className={cx(
            { [classes.searchContainer]: true },
            applyContentfulStyles(BindToFields.FAQForm)
          )}
          component="form"
        >
          <Typography variant="h1" className={classes.title}>
            {!isZipJob ? t('faq.howCanWeHelp') : t('faq.faq')}
          </Typography>
          <SearchInput
            onChange={setSearchTerm}
            className={classes.searchInput}
            inputProps={{
              placeholder: !isZipJob
                ? t('faq.searchFAQs')
                : `Search ${brandName}`,
            }}
            value={searchTerm}
          />
          {searchTerm && faqGroups.length > 0 && (
            <Typography
              className={cx(
                classes.resultCount,
                !isZipJob && classes.resultCountTR
              )}
            >
              <Trans
                i18nKey="faq.results"
                components={{
                  mark: <mark />,
                }}
                values={{
                  searchTerm,
                  resultCount: faqGroups.reduce(
                    (acc, group) => acc + group.faqs.length,
                    0
                  ),
                }}
              />
            </Typography>
          )}
        </Box>
        <Box
          className={cx(
            classes.contentContainer,
            applyContentfulStyles(BindToFields.WrapperThreeFAQGroups)
          )}
        >
          <Container
            className={cx(
              classes.contentContainerInner,
              !isZipJob && classes.contentContainerInnerTR
            )}
            disableGutters
          >
            {faqGroups.length > 0 && (
              <Container
                component="nav"
                className={cx(
                  classes.groupNavigation,
                  !isZipJob && classes.groupNavigationTR
                )}
              >
                {faqGroups.map((group) => (
                  <JumpLink
                    hash={`faq-${kebabCase(
                      insertHandlebarsTemplate(group.title, handlebarsContext)
                    )}`}
                    key={`faq-nav-${group.contentful_id}`}
                  >
                    {insertHandlebarsTemplate(group.title, handlebarsContext)}
                  </JumpLink>
                ))}
              </Container>
            )}
            <Container
              // @ts-ignore
              ref={faqContainer}
              className={cx(
                classes.faqContainer,
                !isZipJob && classes.faqContainerTR
              )}
              component="div"
            >
              {faqGroups.length > 0 &&
                faqGroups.map((group) => (
                  <FAQGroup
                    group={group}
                    key={group.contentful_id}
                    variant="faq-page"
                    forceExpand={!!searchTerm}
                    id={`faq-${kebabCase(
                      insertHandlebarsTemplate(group.title, handlebarsContext)
                    )}`}
                    className={classes.faqGroup}
                  />
                ))}
              {faqGroups.length < 1 && <NotFound />}
            </Container>
          </Container>
        </Box>
      </Box>
    </XRayWrapper>
  )
}

const useStyles = makeStyles()((theme) => {
  const { isZipJob } = useBrand()

  return {
    root: {
      backgroundColor: isZipJob
        ? theme.palette.background.paper
        : theme.palette.newColors.greyBackground,
      padding: '4.03125rem 0 0',
      [theme.breakpoints.down('lg')]: {
        padding: '2.11em 0 0',
      },
    },
    title: {
      marginBottom: '3.125rem',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('lg')]: {
        fontSize: '2.13em',
        marginBottom: '2.5rem',
      },
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 6% 0 6%',
    },
    searchInput: {
      maxWidth: '45.6875rem',
      width: '100%',

      [theme.breakpoints.down('md')]: {
        padding: '0',

        '& button': {
          borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
          minWidth: 'unset',
          padding: '.75rem',

          '& svg': {
            height: '1.25rem',
            width: '1.25rem',
          },
        },
        '& input': {
          fontSize: '1rem',
        },
      },
    },
    resultCount: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      marginTop: '1rem',
      maxWidth: '45.6875rem',
      width: '100%',
      marginLeft: '7.34375rem',

      '& mark': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
      },

      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        marginLeft: 0,
      },
    },
    resultCountTR: {
      '& mark': {
        color: theme.palette.warning.main,
      },
    },
    faqContainer: {
      '& mark': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
    },
    faqContainerTR: {
      '& mark': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.primary.dark,
      },
    },
    faqGroup: {
      marginBottom: '3.8125rem',
    },
    groupNavigation: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.25rem',
      lineHeight: 2.5,
      maxWidth: '18.5rem',

      '& a': {
        color: theme.palette.text.primary,
      },

      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    groupNavigationTR: {
      '& > a': {
        borderBottom: `1px solid ${theme.colors.gray['a200']}`,
        padding: '1rem 0',
      },
    },
    contentContainer: {
      backgroundColor: theme.palette.background.default,
      marginTop: '3.125rem',
      paddingTop: '3.125rem',
      maxWidth: 'unset',
    },
    contentContainerInner: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 6% 0 6%',
    },
    contentContainerInnerTR: {
      maxWidth: 'none',
    },
  }
})

export default FAQPage
