import React, { useState, useMemo, useCallback } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import useSearchParam from 'react-use/lib/useSearchParam'

import { FAQGroup } from '../../types/faq'

interface UseFAQSearch {
  searchTerm: string
  setSearchTerm: React.ChangeEventHandler<HTMLInputElement>
  faqGroups: FAQGroup[]
}

export default function useFAQSearch(faqGroups: FAQGroup[]): UseFAQSearch {
  const searchQueryParam = useSearchParam('s')
  const [searchTerm, setSearchTerm] = useState(searchQueryParam || '')

  // Pre-parse the question and answer to plain text for filtering. This is done
  // because JSON.parse on each key stroke is hell on computers.
  const parsedFAQs = useMemo(
    () =>
      faqGroups.map((group) => ({
        ...group,
        faqs: group.faqs.map((faq) => ({
          ...faq,
          question: documentToPlainTextString(
            JSON.parse(faq.question.raw)
          ).toLowerCase(),
          answer: documentToPlainTextString(
            JSON.parse(faq.answer.raw)
          ).toLowerCase(),
        })),
      })),
    // faqGroups won't change between renders as it's coming from the gatsby
    // store.
    /* eslint-disable react-hooks/exhaustive-deps */
    [faqGroups.length]
  )

  // On each searchTerm change, get the matching contentful_ids from parsedFAQs
  // and then filter faqGroups and it's FAQs for display.
  const filteredFAQs = useMemo(() => {
    const matchingFAQContentfulIDs = parsedFAQs.flatMap((group) =>
      group.faqs
        .map((faq) =>
          faq.question.includes(searchTerm) || faq.answer.includes(searchTerm)
            ? faq.contentful_id
            : null
        )
        .filter((contentfulID): contentfulID is string => !!contentfulID)
    )

    if (matchingFAQContentfulIDs.length === 0) {
      return []
    }

    return faqGroups
      .map((group) => {
        const matchingFAQs = group.faqs.filter((faq) =>
          matchingFAQContentfulIDs.includes(faq.contentful_id)
        )

        if (matchingFAQs.length === 0) {
          return null
        }

        return {
          ...group,
          faqs: matchingFAQs,
        }
      })
      .filter((group): group is FAQGroup => !!group)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [searchTerm])

  const handleSearchTermChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((event) => setSearchTerm(event.target.value), [])

  return {
    searchTerm,
    setSearchTerm: handleSearchTermChange,
    faqGroups: searchTerm ? filteredFAQs : faqGroups,
  }
}
