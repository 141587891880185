import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FAQ from './index'
import { FAQGroup as FAQGroupType } from '../../types/faq'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import useHide from '../../hooks/useHide'
import { BGVariants } from '../../types/heroTextSection'

export interface FAQGroupProps {
  group: FAQGroupType
  variant?: 'faq-page'
  forceExpand?: boolean
  id?: string
  className?: string
  faqSection?: boolean
  sectionClass?: string
}

const FAQGroup: React.FC<FAQGroupProps> = ({
  group,
  variant,
  forceExpand = false,
  id,
  className,
  faqSection = false,
  sectionClass,
}) => {
  const { classes, cx } = useStyles({ group, variant })
  const handlebarsContext = useHandlebarsContext()
  const hideClasses = useHide(group.hideOn)
  return (
    <Box
      data-contentful-id={group.contentful_id}
      component="section"
      className={cx(classes.root, className, hideClasses)}
      id={id}
    >
      <Box
        className={cx({
          [classes.content]: true,
          [classes.contentSection]: !!faqSection,
          // @ts-ignore
          [classes.faqSection]: !!sectionClass && !!faqSection,
        })}
      >
        <Typography variant="h2" className={classes.title}>
          {insertHandlebarsTemplate(group.title, handlebarsContext)}
        </Typography>
        {group.faqs.map((faq, index) => (
          <FAQ
            key={faq.contentful_id}
            {...faq}
            forceExpand={index === 0 ? true : forceExpand}
          />
        ))}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles<{ group: FAQGroupType; variant?: 'faq-page' }>()(
  (theme, { group, variant }) => {
    const bgColors = {
      [BGVariants.Grey]: theme.palette.newColors.greyBackground,
      Paper: theme.palette.common.white,
      White: theme.palette.background.default,
    }

    const padding = variant === 'faq-page' ? 0 : '3.375rem 0 4rem'

    const titleAlign = variant === 'faq-page' ? 'left' : 'center'
    const titleMarginBottom = variant === 'faq-page' ? 0 : '1.65625rem'
    const titleMarginBottomSm = variant === 'faq-page' ? 0 : '1rem'

    return {
      root: {
        // @ts-ignore
        backgroundColor: bgColors[group] ?? 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: padding,
      },
      content: {
        maxWidth: '56rem',
        [theme.breakpoints.down('md')]: {
          padding: '0',
        },
      },
      contentSection: {
        maxWidth: '100%',
      },
      faqSection: {
        paddingLeft: 'calc((100% - 91rem)/2 + 19.5rem)',
        paddingRight: 'calc((100% - 91rem)/2 + 19.5rem)',
        [theme.breakpoints.down('md')]: {
          paddingLeft: '6%',
          paddingRight: '6%',
        },
      },
      title: {
        textAlign: titleAlign,
        marginBottom: titleMarginBottom,
        [theme.breakpoints.down('md')]: {
          fontSize: '1.81rem',
          lineHeight: 1.21,
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: titleMarginBottomSm,
        },
      },
    }
  }
)

export default FAQGroup
