import React from 'react'
import { Container, Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BinocularsSVG from '../../images/binoculars.inline.svg'

import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Props {
  showArticleText?: boolean
}

const NotFound: React.FC<Props> = ({ showArticleText = false }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Container className={classes.main} disableGutters>
      <Box className={classes.header}>
        <Container className={classes.imageContainer}>
          <BinocularsSVG />
        </Container>
        <Typography className={cx(classes.headerTitle)} variant={'h1'}>
          {t('search.notFound')}
        </Typography>
        {showArticleText && <Typography> {t('search.notFoundSub')}</Typography>}
        <Typography> {t('search.notFoundText')}</Typography>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  headerTitle: {
    fontSize: '1.812em',
    fontWeight: 'bold',
    lineHeight: 1.28,
    margin: '1em 0',
  },
  main: {
    maxWidth: '100%',
    padding: '6.75rem 10%',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'unset',
      width: '100%',
    },
  },
  imageContainer: {
    maxWidth: '30em',
  },
}))

export default NotFound
