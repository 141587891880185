import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import XRayContext from '../Providers/XRayContext'

interface Props {
  id: string
  children: React.ReactNode
}

const XRayWrapper: React.FC<Props> = ({ id, children }) => {
  const { classes, cx } = useStyles()
  const componentContext = useContext(XRayContext)

  const clickEvent = (contentfulId: string) => {
    componentContext.setXRayId(contentfulId)
  }

  if (componentContext?.xRay) {
    return (
      <Box
        className={cx({
          [classes.box]: componentContext.id !== id,
          [classes.boxClicked]: componentContext.id === id,
        })}
        onClick={() => clickEvent(id)}
      >
        {children}
      </Box>
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}

const useStyles = makeStyles()({
  box: {
    '&:hover': {
      border: '3px solid pink',
    },
  },
  boxClicked: {
    border: '3px solid orange',
  },
})

export default XRayWrapper
