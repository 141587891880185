import React from 'react'
import { Button, Input, InputProps, Theme, useMediaQuery } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import { Search } from '@mui/icons-material'
import useBrand from '../../hooks/useBrand'

const SearchInput = withStyles(
  ({ inputProps, ...props }: InputProps) => {
    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('lg')
    )
    const { isZipJob } = useBrand()

    return !isZipJob ? (
      <Input
        startAdornment={<Search className="startIconAdornment" />}
        inputProps={{ type: 'search', ...inputProps }}
        {...props}
      />
    ) : (
      <Input
        startAdornment={
          !isMobile ? <Search className="startIconAdornment" /> : null
        }
        endAdornment={
          isMobile ? (
            <Button type="submit">
              <Search />
            </Button>
          ) : null
        }
        inputProps={{ type: 'search', ...inputProps }}
        {...props}
      />
    )
  },
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6.5px',
      padding: '0.5625rem 1.125rem',

      '&.Mui-focused': {
        border: `1px solid ${theme.palette.primary.main}`,
      },

      '& > .startIconAdornment': {
        color: theme.palette.primary.main,
      },
    },
    input: {
      fontSize: '1.25em',
      marginLeft: '0.9rem',

      '&&&:focus': {
        border: `2px solid transparent`,
      },
    },
  })
)

export default SearchInput
